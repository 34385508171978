<script>
export default {};
</script>

<template>
    <div class="v-planning-portfolio">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="col-12">
                <card>
                    <h3>{{ $t("reports.planning_milestone_report") }}</h3>
                    <p>
                        {{
                            $t("reports.planning_milestone_report_description")
                        }}
                    </p>

                    <template v-if="false"
                        >all projets / specific projects / with changes
                        since?</template
                    >
                    <a href="" class="btn btn-primary"
                        ><i class="far fa-file-csv"></i> {{ $t("download") }}</a
                    >
                </card>
            </div>
        </div>
    </div>
</template>
